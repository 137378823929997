import React, { useState, useEffect } from "react"

const PostInfo = props => {

  useEffect(() => {
  }, [props.errorMessage])

  useEffect(() => {

  }, [props.coverInfo])

  return (
    <div>
      <div style={{ width: "100%" }}>
        <div className="flex flex-wrap justify-center items-center pricing-ins go-previous"
             style={{ textAlign: "left", marginBottom: "20px", float: "left" }} onClick={props.goPreviousStep}>
          <i className="fa fa-arrow-left" style={{ marginRight: "5px" }}></i>
        </div>
      </div>
      <div style={{ width: "100%" }}>
        {props.coverInfo && (
          <div className="flex checkout-card"
               style={{ flexDirection: "column", paddingTop: "0", paddingBottom: "0", width: "100%" }}>
            <div className="flex">
              <div
                style={{ borderWidth: "0px" }}
                className="flex items-center px-3 py-2 border-2 rounded-md border-gray-200 focus:outline-none focus:border-gray-500 transition-colors"
              >
                <div style={{ borderRadius: "5px", overflow: "hidden" }}>
                  <img src={props.coverInfo.avatar} className="w-12 h-12" />
                </div>
                <div className="ml-2" style={{ textAlign: "left", paddingTop: "16px" }}>
                  <p style={{ fontSize: "17px", fontWeight: "500", margin: 0 }}>@{props.coverInfo.nickname}</p>
                  <p style={{
                    fontWeight: "500",
                    color: "gray"
                  }}>{props.cartInfo.cartItems[0].times} {props.cartInfo.cartItems[0].subCategory}/
                    ${props.cartInfo.cartItems[0].amount}</p>
                </div>
              </div>
            </div>
          </div>
        )}
        {props.coverInfo && (
          <div className="flex items-center checkout-card" style={{ flexDirection: "column" }}>
            <div style={{ width: "100%", textAlign: "left" }} className="checkout-title flex">
              <h2>Select a Post</h2>
            </div>
            <div className="checkout-post-container clearfix">
              {props.coverInfo.videos && (
                props.coverInfo.videos.map(video => (
                  <div key={video.id} className="checkout-post" onClick={(e) => props.handleSelectPost(video, e)}>
                    <div className="checkout-post-overview">
                      <div>
                        <i className="fa fa-heart" style={{ color: "white" }}></i> {props.cartInfo.cartItems[0].times}
                      </div>
                    </div>
                    <img src={video.cover_url} />
                  </div>
                ))
              )}
            </div>
          </div>
        )}
        <div>
          <div className="block w-full mx-auto mt-3 py-3 text-center text-white">
            <button
              onClick={() => props.handleProcessPostSelection()}
              className="rounded-full py-1 px-8 ant-btn ant-btn-primary checkout-button w-full"
              disabled={props.loading}
            >
              {props.loading && (
                <div className="lds-dual-ring"></div>
              )}
              <span style={{ marginLeft: "5px" }}>{"Continue"}</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  )

}

export default PostInfo
