import React from "react"
import { navigate } from "gatsby"
import Logo from "./../../../../static/img/logo_black.png"

const RealBoostLogo = () => {
  return (
    <button onClick={() => navigate(`/`)}>
      <img
        src={Logo}
        className="menu-logo color-black"
        alt="logo boostik"
        style={{ width: "130px", marginLeft: "20px" }}
      />
    </button>
  )
}

export default RealBoostLogo
